import "./Banner.css";
import Form from "../Form/Form";
import { Link } from "react-router-dom";

const Banner = (props) => {
  return (
    // Define top content/Banner
    <div className={`heading ${props.headerClass}`} id="banner">
      <div>
        <p className={props.headerTextClass}>{props.headingText}</p>

        <div className="centered">
          <p>{props.subtitle}</p>

          {(() => {
            if (props.add) {
              return <Form />;
            } else {
              return (
                <Link to={`/${props.link}`}>
                  <button className="quote">GET A QUOTE</button>
                </Link>
              );
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default Banner;
