import { useState } from "react";
import "./Form.css";
import Buttons from "../Buttons/Buttons";
import { send } from "emailjs-com";
// import Modal from 'react-modal';




const Form = () => {
  const [isModal, setIsModal] = useState(false);


  
  const [toSend, setToSend] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  
  const onSubmit = (e) => {
    e.preventDefault();
    
    /* --- METHOD TO SEND THE MAIL --- */ 
    
    send("service_wqw9dgk", "template_wove9le", toSend, "veJTdD5atnXuetcK_")
    .then((response) => {
      console.log("SUCCESS!", response.status, response.text);
      setIsModal(true)
      setToSend({
        name: "",
        phone: "",
        email: "",
        message: "",
      });
      console.log(isModal)
               
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
      
      console.log(toSend)
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <> 
    { isModal &&
      <div className="popup_content">
            <div className="popup">
              <p className="pop_text">
                Thank you for contacting Tech Tanks Solutions Nigeria Limited. A
                member of the team will respond to your mail soon
              </p>
              <Buttons
                value="BACK TO PAGE"
                onClick={()=> setIsModal(false)}
                link="contact-us"
                type="submit"
                buttontype="btn orange_btn small_btn"
              />

              {/* <button
                onClick={()=> setIsModal(false)}
                type="submit"
                className="btn orange_btn small_btn"
              >BACK TO PAGE</button> */}
            </div>
          </div>}
        

    <form onSubmit={onSubmit} className="form">
      
      <p className="form_header">Send us a message.</p>
      <div>
        <label htmlFor="name">Name</label>
        <input
          value={toSend.name}
          onChange={handleChange}
          placeholder="Name"
          type="text"
          name="name"
          required
        />
      </div>
      <div>
        <label htmlFor="phone">Phone</label>
        <input
          value={toSend.phone}
          onChange={handleChange}
          placeholder="Phone number"
          type="tel"
          pattern="[0-9]{11}"
          name="phone"
          required
        />
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input
          value={toSend.email}
          onChange={handleChange}
          placeholder="Email address"
          type="email"
          name="email"
          required
        />
      </div>

      <div>
        <label htmlFor="message">Message</label>
        <textarea
          value={toSend.message}
          onChange={handleChange}
          placeholder="Message"
          name="message"
          required
        />
      </div>
      <button type="submit" className="btn orange_btn">SEND MESSAGE</button>
    </form>
    </>
  );
};

export default Form;
