import Banner from "../components/Banner/Banner";
import "../Styles/Aboutus.css";
import BigHead from "../components/BigHead";
import ABT1 from "../images/abt1.jpg";
import ABT2 from "../images/abt2.jpg";
import IMG1 from "../images/img1.jpg";
import IMG2 from "../images/img2.jpg";
import Study from "../components/Study/Study";

const AboutUs = () => {
  const data = [
    {
      id: 1,
      title: "Simplicity",
      text: "Finding the simplest solutions to make life simple for our clients, services, and ourselves is at the heart of what we do.",
    },
    {
      id: 2,
      title: "Integrity and honesty",
      text: "Our company upholds a code of values that are especially moral or artistic, which motivates our sincerity and steadfast moral principles.",
    },
    {
      id: 3,
      title: "Good customer relations",
      text: "Since customers are the most crucial component of any business, we made sure to maintain long-lasting relationships and offer a win-win situation with excellent results.",
    },
    {
      id: 4,
      title: "Commitment",
      text: "We are committed to giving our clients and consumers excellent results.",
    },
    {
      id: 5,
      title: "Reliability",
      text: "We believe in bringing reliable professionals and strategists together to provide a reliable service to our clients",
    },
  ];
  return (
    <>
      <div className="Aboutus_banner">
        <div>
          <Banner
            headerClass="white-banner"
            headerTextClass="white-title"
            headingText="WE ARE TECH TANKS"
            subtitle="TechTanks is an IT solutions (Web, mobile app, custom software development, type approval, etc) company in Nigeria. We are a diverse group of digital strategists, technologists, critical thinkers, and thought leaders. We offer a comprehensive set of software development and technical services from requirements discovery to support and promote high quality and continuous improvement. Together, we aim to co-invent the future by adding our unique expertise pulled from a diverse portfolio of industries."
            link="contact-us"
          />
        </div>
      </div>
      <div className="about_img">
        <div className="splitImg">
          <img src={ABT2} alt="" className="bannerImg" />
          <img src={ABT1} alt="" className="bannerImg" />
        </div>
      </div>

      <div className="aboutus_top">
        <div>
          <BigHead
            title="OUR HISTORY"
            text="TechTank’s team of professionals are committed to the highest standards in regards to the quality of work we offer our customers. "
          />
          <Study
            split="split"
            image={IMG1}
            imageClass="study_img_long"
            image1={IMG2}
            imageClass1="study_img_small"
          />
        </div>
      </div>
      <div className="aboutus_content">
        <div>
          <BigHead title="OUR CORE VALUES" />
          {data.map((data) => (
            <div className="aboutus_value">
              <p className="title">{data.title}</p>
              <p className="text">{data.text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AboutUs;
