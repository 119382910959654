import Banner from "../components/Banner/Banner";
import "../Styles/ContactUs.css";
import WhatsApp from "../images/WhatsApp.png";

const ContactUs = () => {
  return (
    <div className="contactus">
      <Banner
        headerClass="green-banner align-center"
        headerTextClass="green-title"
        headingText="START YOUR PROJECT"
        subtitle="TechTank's team of professionals are committed to the highest standards in regards to the quality of work we offer our customers. "
        add="add"
      />
      <div className="contact_container">
        <div>
          <div>
            <p className="contactBig">
              If you prefer, send us an email for new business opportunities or
              job openings.
            </p>
          </div>
          <div className="contact_details">
            <div className="">
              <a href="mailto:hello@techtanks.tech" className="title">
                hello@techtanks.tech
              </a>
              <p className="text">New business and general.</p>
            </div>
            <div className="">
              <a href="https://www.google.com/maps/place/Zone+7,+5+Sirasso+Cl,+Wuse+904101"
                target="_blank"
                className="title" >
                No. 5 Siraso close, Wuse Zone 7, Abuja - Nigeria
              </a>
              <p className="text">If you wish to visit us at out office.</p>
            </div>
            <div className="">
              <div>
                <a
                  href="https://wa.me/2347063530869"
                  target="_blank"
                  className="whatsapp"
                >
                  <img src={WhatsApp} alt="" />
                  <p className="title">+234 70 6353 0869</p>
                </a>
              </div>
              <p className="text">Message us on whatsApp</p>
            </div>
            <div className="">
              <p className="title">Social media</p>
              <ul className="text">
                <li>
                  <a
                    href="https://ng.linkedin.com/in/techtanks-solutions-nigeria-limited-b7b430234"
                    target="_blank"
                  >
                    Linkedin: TechTanks Solutions Nigeria Limited
                  </a>{" "}
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/tech_tanks/"
                    target="_blank"
                  >
                    Instagram: @tech_tanks>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/techtanks" target="_blank">
                    Twitter: @techtanks
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
