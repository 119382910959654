import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop"

import "./Styles/styles.css";
// import "./App.css";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

// const Home = React.lazy(() => import("./pages/Home"));
import Home from "./pages/Home";
import Services from "./pages/Services";
// const Services = React.lazy(() => import("./pages/Services"));
import Works from "./pages/Works";
// const Works = React.lazy(() => import("./pages/Works"));
import AboutUs from "./pages/AboutUs";
// const AboutUs = React.lazy(() => import("./pages/AboutUs"));
import ContactUs from "./pages/ContactUs";
// const ContactUs = React.lazy(() => import("./pages/ContactUs"));
import Dosimetry from "./pages/Cases/Dosimetry";
// const Dosimetry = React.lazy(() => import("./pages/Cases/Dosimetry"));
import Ecommerce from "./pages/Cases/Ecommerce";
// const Ecommerce = React.lazy(() => import("./pages/Cases/Ecommerce"));
import Loan from "./pages/Cases/Loan";
// const Loan = React.lazy(() => import("./pages/Cases/Loan"));
import Poverty from "./pages/Cases/Poverty";
// const Poverty = React.lazy(() => import("./pages/Cases/Poverty"));


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop />
        <Navbar />
        <Routes>

          <Route exact path="/" element={
          // <React.Suspense fallback={<>...</>}>
          <Home />
        // </React.Suspense>
      } />

          <Route exact path="/services" element={
          // <React.Suspense fallback={<>...</>}>
            <Services />
          // </React.Suspense>
        } />

          <Route exact path="/works" element={
          // <React.Suspense fallback={<>...</>}>
          <Works />
        // </React.Suspense>
      } />

          <Route exact path="/contact-us" element={
          // <React.Suspense fallback={<>...</>}>
          <ContactUs />
        // </React.Suspense>
      } />

          <Route exact path="/about-us" element={
          // <React.Suspense fallback={<>...</>}>
          <AboutUs />
          // </React.Suspense>
        } />

          <Route exact path="/dosimetry" element={
          // <React.Suspense fallback={<>...</>}>
            <Dosimetry />
          // </React.Suspense>
        } />

          <Route exact path="/ecommerce" element={
          // <React.Suspense fallback={<>...</>}>
            <Ecommerce />
          // </React.Suspense>
        } />

          <Route exact path="/loan" element={
          // <React.Suspense fallback={<>...</>}>
            <Loan />
          // </React.Suspense>
        } />

          <Route exact path="/poverty" element={
          // <React.Suspense fallback={<>...</>}>
            <Poverty />
          // </React.Suspense>
        } />

          <Route path="*" element={<Home />}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
